import { Grid } from '@material-ui/core';
import React from 'react';
import { Button, Container, Form, Navbar, NavDropdown } from 'react-bootstrap';
import Ratebuckets_logo from '../images/Ratebuckets_logo.png';

export const Nav = () => {
  return (
    <>
      <Navbar className="justify-content-center" collapseOnSelect expand="sm">
        <>
          <Navbar.Brand href="#home" className="justify-content-center">
            <h1 className="font-weight-bold">
              <a href="/" rel="noreferrer" className="text-decoration-none">
                <img
                  className="w-100"
                  // src={
                  //   'https://res.cloudinary.com/ratebuckets/image/upload/v1640190469/ratebuckets_main_logo_yb45li.png'
                  // }
                  src={Ratebuckets_logo}
                  alt=""
                  height="50"
                />
              </a>
            </h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />

          {/* <Navbar.Collapse id="navbarScroll" className="justify-content-end">
            <Navbar.Text className="">
              <a
                className="text-right text-decoration-none"
                href="mailto: info@ratebuckets.com"
              >
                <button
                  type="button"
                  class="btn "
                  style={{ backgroundColor: '#7e87ad', color: '#f4f4f4' }}
                >
                  <b>info@ratebuckets.com</b>
                </button>
              </a>
            </Navbar.Text>
            &emsp;
            <Navbar.Text>
              <a
                className="text-right text-decoration-none"
                href="https://radar.ratebuckets.com/"
                target="_blank"
              >
                <button
                  type="button"
                  class="btn text-light"
                  style={{
                    backgroundColor: '#516B8F',
                  }}
                >
                  Login
                </button>
              </a>
            </Navbar.Text>
          </Navbar.Collapse> */}
        </>
      </Navbar>

      {/* <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#">Navbar scroll</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav.Link href="#action1">Home</Nav.Link>
              <Nav.Link href="#action2">Link</Nav.Link>
              <NavDropdown title="Link" id="navbarScrollingDropdown">
                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action4">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Something else here
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#" disabled>
                Link
              </Nav.Link>
            </Nav>
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              />
              <Button variant="outline-success">Search</Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
    </>
  );
};
