import { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Ratio,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap';

import { Nav } from './Nav';
import { Footer } from './Footer';
import { ContactForm } from './ContactForm';
import { storeData } from '../services/service';

export const Home = () => {
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState(null);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);

  const [inputs, setInputs] = useState({});

  const handleInputChange = (e) =>
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

  const storeUserData = (e) => {
    // console.log(`sd ${JSON.stringify(inputs)}`);
    e.preventDefault();
    if (
      inputs.email != undefined &&
      inputs.f_name != undefined &&
      inputs.company != undefined
    ) {
      if (inputs.email != '' && inputs.f_name != '' && inputs.company != '') {
        if (inputs.message == undefined) {
          inputs.message = '';
        }
        storeData(inputs).then((res) => {
          setMsg('We will contact you ASAP.');
          setShow(true);
        });
      } else {
        setMsg('Please Fill the required fileds');
        setShow(true);
      }
    } else {
      setMsg('Please Fill the required fileds');
      setShow(true);
    }
    setInputs({});
  };

  return (
    <div className="my-2">
      <Row>
        <ContactForm
          open={open}
          handleClose={handleClose}
          setShow={setShow}
          setMsg={setMsg}
        />
      </Row>

      <Row className="zindex-tooltip fixed-top">
        <Col xs={12}>
          <ToastContainer position={'top-center'}>
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={6000}
              autohide
              style={{ zIndex: 10000, background: '#f4f4f4' }}
            >
              <Toast.Header style={{ zIndex: 10000, background: '#f4f4f4' }}>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Team Ratebuckets</strong>
                <small>Just Now</small>
              </Toast.Header>
              <Toast.Body style={{ zIndex: 10000, background: '#f4f4f4' }}>
                {' '}
                <p className="font-weight-bold text-success pt-2">{msg}</p>
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </Col>
      </Row>

      <Nav />
      <div className="container my-5" style={{ width: 'auto', height: 'auto' }}>
        <Row className="d-flex justify-content-center">
          <div className="mb-2">
            <h4 className="container align-self-center lh-base">
              Ratebuckets is the only tool that uses{' '}
              <b>large competitive data sets</b>, of <b>every hotel</b> in your
              market, and <b>Machine Learning</b>, to extract unbiased price
              positioning insights that are hidden from traditional comp set
              tools.
            </h4>
          </div>
          <Col md={10}>
            <Ratio aspectRatio="16x9" className="border border-dark">
              <iframe
                class="embed-responsive-item"
                src="https://screenpal.com/player/c0f2lgVa8w5?ff=1&dcc=1&a=1&bg=transparent&embed=1&width=100%&height=100%"
                allowfullscreen="true"
              ></iframe>
            </Ratio>

            <Button
              variant="btn w-100 text-light"
              style={{ backgroundColor: '#aa9172' }}
              onClick={handleShow}
            >
              <i class="fa fa-share" aria-hidden="true"></i> Share
            </Button>
          </Col>
        </Row>

        <div className="my-5">
          <Row
            className="d-flex justify-content-center"
            style={{ color: '#516B8F', fontWeight: 'bold' }}
          >
            <Col md={9}>
              <Card>
                <Card.Header>
                  <br />
                  <p className="text-center">Interested in Ratebuckets?</p>
                  <p className="text-center">
                    {' '}
                    Submit your info below to engage one of our Onboarding
                    Specialists.
                  </p>
                </Card.Header>
                <Card.Body>
                  <Form className="container">
                    <Row className="mb-2">
                      <Form.Group as={Col}>
                        <Form.Label className="required">Full Name</Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder="Enter First Name"
                          name="f_name"
                          value={inputs.f_name || ''}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label className="required">
                          Hotel / Company Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder="Enter Last Name"
                          name="company"
                          value={inputs.company || ''}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Row>

                    <Form.Group className="mb-2" controlId="formBasicEmail">
                      <Form.Label className="required">Email</Form.Label>
                      <Form.Control
                        type="email"
                        // placeholder="Enter email"
                        name="email"
                        value={inputs.email || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>

                    {/* <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="message"
                        value={inputs.message || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group> */}
                    <Button
                      variant="btn"
                      style={{ backgroundColor: '#516B8F' }}
                      type="submit"
                      onClick={storeUserData}
                      className="w-100 text-light"
                    >
                      <i class="fa fa-send"></i> Send
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Footer />
    </div>
  );
};
