import React from 'react';

export const Footer = () => {
  return (
    <>
      <div class="">
        <p class="text-muted small mb-0 font-weight-bold text-center">
          <a
            className="text-decoration-none text-dark btn text-center"
            href="https://ratebuckets.com"
            target="_blank"
          >
            &copy; Copyright {new Date().getFullYear()} Ratebuckets, LLC · All
            rights reserved.
          </a>
        </p>
        <p class="text-muted small mb-0 font-weight-bold text-center">
          <a
            className="text-decoration-none text-dark btn text-center fs-6"
            href="tel:13054206310"
          >
            Miami: +1 305 420 6310
          </a>{' '}
          |{' '}
          <a
            className="text-decoration-none text-dark btn text-center fs-6"
            href="tel:34671263507"
          >
            Madrid: +34 671 26 35 07
          </a>
        </p>
      </div>
    </>
  );
};
