import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Navbar,
  Ratio,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap';
import { sendEmail, storeData } from '../services/service';
import { Footer } from './Footer';
import { Nav } from './Nav';
import { ContactForm } from './ContactForm';

export const DemoVideo = () => {
  const [inputs, setInputs] = useState({});
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState(null);

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);

  const handleInputChange = (e) =>
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

  const storeUserData = (e) => {
    // console.log(`sd ${JSON.stringify(inputs)}`);
    e.preventDefault();
    if (
      inputs.email != undefined &&
      inputs.f_name != undefined &&
      inputs.l_name != undefined &&
      inputs.message != undefined
    ) {
      if (
        inputs.email != null &&
        inputs.f_name != null &&
        inputs.l_name != null &&
        inputs.message != null
      ) {
        storeData(inputs).then((res) => {
          setMsg('We will contact you ASAP.');
          setShow(true);
        });
      }
    }
    setInputs({});
  };

  return (
    <div className="my-5">
      <Row>
        <ContactForm
          open={open}
          handleClose={handleClose}
          setShow={setShow}
          setMsg={setMsg}
        />
      </Row>

      <Row>
        <Col xs={12} className="bg-light">
          <ToastContainer position={'top-center'}>
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={6000}
              autohide
              className="bg-light"
            >
              <Toast.Header className="bg-light">
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Team Ratebuckets</strong>
                <small>Just Now</small>
              </Toast.Header>
              <Toast.Body className="bg-light">
                {' '}
                <p className="font-weight-bold text-success pt-2">{msg}</p>
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </Col>
      </Row>
      <Nav />
      <div className="container mt-5" style={{ width: 'auto', height: 'auto' }}>
        <Row className="d-flex justify-content-center">
          <Col md={10}>
            {/* <Ratio aspectRatio="16x9" className="border border-dark">
            <embed
                type="image/svg+xml"
                // src="https://fast.wistia.net/embed/iframe/l2acje3acm?videoFoam=true"
                src="https://screenpal.com/player/c0f2lgVa8w5?ff=1&dcc=1&a=1&bg=transparent&embed=1&width=100%&height=100%"
                allowfullscreen="true"
              />
            </Ratio> */}
            <Ratio aspectRatio="16x9" className="border border-dark">
              <iframe
                class="embed-responsive-item"
                src="https://screenpal.com/player/c0f2lgVa8w5?ff=1&dcc=1&a=1&bg=transparent&embed=1&width=100%&height=100%"
                allowfullscreen="true"
              ></iframe>
            </Ratio>
            <div class="d-flex justify-content-end">
              {' '}
              <Button
                variant="btn w-100 text-light"
                style={{ backgroundColor: '#aa9172' }}
                onClick={handleShow}
              >
                <i class="fa fa-share" aria-hidden="true"></i> Share
              </Button>
            </div>
          </Col>
        </Row>

        <div className="my-5">
          <Row
            className="d-flex justify-content-center"
            style={{ color: '#516B8F', fontWeight: 'bold' }}
          >
            <Col md={9}>
              <Card>
                <Card.Header>
                  <br />
                  <p className="text-center">Interested in Ratebuckets?</p>
                  <p className="text-center">
                    {' '}
                    Submit your info below to engage one of our Onboarding
                    Specialists.
                  </p>
                </Card.Header>
                <Card.Body>
                  <Form className="container">
                    <Row className="mb-2">
                      <Form.Group as={Col}>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder="Enter First Name"
                          name="f_name"
                          value={inputs.f_name || ''}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder="Enter Last Name"
                          name="l_name"
                          value={inputs.l_name || ''}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Row>

                    <Form.Group className="mb-2" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        // placeholder="Enter email"
                        name="email"
                        value={inputs.email || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        name="message"
                        value={inputs.l_name || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Button
                      variant="btn"
                      style={{ backgroundColor: '#516B8F' }}
                      type="submit"
                      onClick={storeUserData}
                      className="w-100 text-light"
                    >
                      <i class="fa fa-send"></i> Send
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Footer />
    </div>
  );
};
