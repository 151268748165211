import axios from 'axios';

// const apiURI = 'http://localhost:5000';
const apiURI = 'https://api.ratebuckets.com';

export const sendEmail = async (userD) => {
  await axios.post(`${apiURI}/send-demo-link`, userD).then((result) => {
    const res = result.data;
    // console.log(res);
    return res;
  });
};

export const storeData = async (userD) => {
  await axios.post(`${apiURI}/save_lead_user`, userD).then((result) => {
    const res = result.data;
    return res;
  });
};
