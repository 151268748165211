import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { sendEmail } from '../services/service';

export const ContactForm = ({ open, handleClose, setShow, setMsg }) => {
  const [inputs, setInputs] = useState({});

  const handleInputChange = (e) =>
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

  const sendDemoLink = (e) => {
    e.preventDefault();
    if (inputs.demo_email != undefined) {
      if (inputs.demo_email != null) {
        sendEmail({ email: inputs.demo_email }).then((res) => {
          setMsg(
            'Please check your mailbox to get the link to Pre-recorded Demo'
          );
          setShow(true);
        });
      }
    }
    handleClose();
    setInputs({});
  };

  return (
    <>
      <Modal show={open} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Share this Demo Link via Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Col} controlId="formBasicEmail">
            <Form.Control
              type="email"
              name="demo_email"
              placeholder="Enter Email"
              aria-label="btn-email"
              value={inputs.demo_email || ''}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="btn text-light"
            style={{ backgroundColor: '#aa9172' }}
            type="submit"
            defaultValue="Get Started"
            onClick={sendDemoLink}
          >
            <i class="fa fa-share" aria-hidden="true"></i> Share
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
